import React, { useState } from 'react';
import './GoogleFormCard.css';

const GoogleFormCard = () => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    window.open('https://forms.gle/HXXqHCjxptwUCHDG6', '_blank');
  };

  return (
    <div className="googleform-container">
      <div className="googleform-card">
        <div className="googleform-img"></div>
        <div className="googleform-textBox">
          <div className="googleform-textContent">
            <p className="googleform-h1">Add Your Business Now!</p>
            <p className="googleform-p">Your Business in Mana Kovvur</p>
          </div>
          <button
            className={`googlelinkform-btn ${isClicked ? 'clicked' : ''}`}
            onClick={handleClick}
          >
            Register Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default GoogleFormCard;
