import React, { useState, useEffect, useRef } from 'react';
import { IoCloseCircle } from "react-icons/io5";
import './YouTubeTrailers.css';

const YouTubeTrailers = () => {
  const [trailers, setTrailers] = useState([]);
  const [kovvurclipsVideos, setKovvurclipsVideos] = useState([]); // Renamed state
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isKovvurclipsPlaying, setIsKovvurclipsPlaying] = useState(false); // Renamed state

  const trailerContainerRef = useRef(null);
  const kovvurclipsContainerRef = useRef(null); // Renamed ref

  const [isDraggingTrailers, setIsDraggingTrailers] = useState(false);
  const [startXTrailers, setStartXTrailers] = useState(0);
  const [scrollLeftTrailers, setScrollLeftTrailers] = useState(0);

  const [isDraggingKovvurclips, setIsDraggingKovvurclips] = useState(false); // Renamed state
  const [startXKovvurclips, setStartXKovvurclips] = useState(0); // Renamed state
  const [scrollLeftKovvurclips, setScrollLeftKovvurclips] = useState(0); // Renamed state

  // Fetch trailers from the trailers API
  const loadTrailers = async () => {
    try {
      const response = await fetch('https://raw.githubusercontent.com/manakovvur/youtube-ads-mana-kovvur/refs/heads/main/Trailers-trending');
      if (!response.ok) throw new Error('Failed to fetch trailers');
      const trailerData = await response.json();
      setTrailers(trailerData);
    } catch (error) {
      console.error('Failed to fetch trailers', error);
    }
  };

  // Fetch kovvurclips videos from the API
  const loadKovvurclipsVideos = async () => {
    try {
      const response = await fetch('https://raw.githubusercontent.com/manakovvur/youtube-ads-mana-kovvur/main/ads-videos.json');
      if (!response.ok) throw new Error('Failed to fetch kovvurclips videos');
      const kovvurclipsData = await response.json();
      setKovvurclipsVideos(kovvurclipsData);
    } catch (error) {
      console.error('Failed to fetch kovvurclips videos', error);
    }
  };

  useEffect(() => {
    loadTrailers();
    loadKovvurclipsVideos();
  }, []);

  const handleTrailerSelection = (index) => {
    setCurrentlyPlaying(index);
    setIsKovvurclipsPlaying(false);
    setIsPopupVisible(true);
  };

  const handleKovvurclipsSelection = (index) => {
    setCurrentlyPlaying(index);
    setIsKovvurclipsPlaying(true);
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
    setCurrentlyPlaying(null);
  };

  // Trailer dragging handlers
  const handleMouseDownTrailers = (e) => {
    setIsDraggingTrailers(true);
    setStartXTrailers(e.pageX - trailerContainerRef.current.offsetLeft);
    setScrollLeftTrailers(trailerContainerRef.current.scrollLeft);
  };

  const handleMouseLeaveTrailers = () => {
    setIsDraggingTrailers(false);
  };

  const handleMouseUpTrailers = () => {
    setIsDraggingTrailers(false);
  };

  const handleMouseMoveTrailers = (e) => {
    if (!isDraggingTrailers || !trailerContainerRef.current) return;
    const x = e.pageX - trailerContainerRef.current.offsetLeft;
    const walk = (x - startXTrailers) * 2;
    trailerContainerRef.current.scrollLeft = scrollLeftTrailers - walk;
  };

  // Kovvurclips dragging handlers
  const handleMouseDownKovvurclips = (e) => {
    setIsDraggingKovvurclips(true);
    setStartXKovvurclips(e.pageX - kovvurclipsContainerRef.current.offsetLeft);
    setScrollLeftKovvurclips(kovvurclipsContainerRef.current.scrollLeft);
  };

  const handleMouseLeaveKovvurclips = () => {
    setIsDraggingKovvurclips(false);
  };

  const handleMouseUpKovvurclips = () => {
    setIsDraggingKovvurclips(false);
  };

  const handleMouseMoveKovvurclips = (e) => {
    if (!isDraggingKovvurclips || !kovvurclipsContainerRef.current) return;
    const x = e.pageX - kovvurclipsContainerRef.current.offsetLeft;
    const walk = (x - startXKovvurclips) * 2;
    kovvurclipsContainerRef.current.scrollLeft = scrollLeftKovvurclips - walk;
  };

  return (
    <div>
      <h2 className="heading-videos">Movie Trailers</h2>
      <div
        className="trailer-container"
        ref={trailerContainerRef}
        onMouseDown={handleMouseDownTrailers}
        onMouseLeave={handleMouseLeaveTrailers}
        onMouseUp={handleMouseUpTrailers}
        onMouseMove={handleMouseMoveTrailers}
      >
        <div className="trailers">
          {trailers.map((trailer, index) => (
            <div
              key={trailer.id}
              className="trailer-card"
              onClick={() => handleTrailerSelection(index)}
            >
              <div
                className="trailer-preview-image"
                style={{ backgroundImage: `url(${trailer.previewImage})` }}
              ></div>
            </div>
          ))}
        </div>
      </div>

      <h2 className="heading-videos">Trending Shorts</h2>
      <div
        className="trailer-container"
        ref={kovvurclipsContainerRef}
        onMouseDown={handleMouseDownKovvurclips}
        onMouseLeave={handleMouseLeaveKovvurclips}
        onMouseUp={handleMouseUpKovvurclips}
        onMouseMove={handleMouseMoveKovvurclips}
      >
        <div className="trailers">
          {kovvurclipsVideos.map((clip, index) => (
            <div
              key={clip.id}
              className="trailer-card kovvurclips-card"
              onClick={() => handleKovvurclipsSelection(index)}
            >
              <div
                className="trailer-preview-image"
                style={{ backgroundImage: `url(${clip.previewImage})` }}
              ></div>
            </div>
          ))}
        </div>
      </div>

      {isPopupVisible && (
        <div className="popup-overlay">
          <div className={`popup-content ${isKovvurclipsPlaying ? 'kovvurclips-popup' : 'trending-popup'}`}>
            <button className="close-icon" onClick={closePopup}><IoCloseCircle /></button>
            {currentlyPlaying !== null && (
              <iframe
                title={`Video ${currentlyPlaying}`}
                width={isKovvurclipsPlaying ? "100%" : "640px"}
                height={isKovvurclipsPlaying ? "100%" : "360px"}
                src={
                  isKovvurclipsPlaying
                    ? `https://www.youtube.com/embed/${kovvurclipsVideos[currentlyPlaying].id}?autoplay=1`
                    : `https://www.youtube.com/embed/${trailers[currentlyPlaying].id}?autoplay=1`
                }
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
              ></iframe>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default YouTubeTrailers;

